<template>
  <ValidationProvider :name="options.label" v-slot="{ errors }" :rules="options.rules">
    <v-radio-group
      v-model="model"
      :label="options.label"
      :error-messages="errors"
    >
      <v-radio
        v-for="item in options.items"
        :key="item.key"
        :label="item.value"
        :value="item.key"
      />
    </v-radio-group>
  </ValidationProvider>
</template>

<script>
export default {
  name: "Field",
  props: ["value", "options"],
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val);
      }
    },
  }
}
</script>

<style scoped>

</style>
